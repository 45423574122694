import { createSelector } from '@reduxjs/toolkit';

import { selectActiveCustomer } from 'features/auth/selectors';
import { selectContractDetails } from 'features/contracts/selectors';
import {
  SubmittedInvoice,
  InvoiceStatusType,
  UploadInvoiceStatusType,
  UploadInvoices
} from 'services/InvoicesService/types';
import { RootState } from 'store';

import { INVOICE_UPLOAD_VALIDATION_MESSAGE_ID } from './consts';
import { InvoicesState, UpcommingPaymentItem } from './types';

type StatusSortWeight = {
  [key in InvoiceStatusType]?: number;
};

type UploadedInvoicesStatus = 'NOT_PROCESSED' | 'WITH_ERRORS' | 'NOT_PROCESSED_WITH_ERRORS' | 'OK';

export const selectCompletionIndex = (state: InvoicesState) => state.completionIndex;
export const selectActiveStep = (state: InvoicesState) => state.activeStep;

export const selectInvoicesList = (state: InvoicesState) => state.invoicesFullReport?.invoices?.content || [];
export const selectInvoicesPageCount = (state: InvoicesState) =>
  state.invoicesFullReport?.invoices?.totalPages || '0';
export const selectIsInvoicesLoading = (state: InvoicesState) => state.isLoading;
export const selectIsInvoicesLoadFailed = (state: InvoicesState) => state.isInvoicesLoadFailed;

export const selectVerifiers = (state: InvoicesState) => state.verifiers;
export const selectVerfiersDetailsWithBuyerInfo = (state: RootState) => {
  const verifiers = selectVerifiers(state.invoices);
  const contractDetails = selectContractDetails(state.contracts);
  const thirdParties = contractDetails?.thirdParties || [];
  const activeCustomer = selectActiveCustomer(state.auth);

  const mappedVerifierDetails = verifiers.verifierDetails
    ?.filter((verifier) => verifier.verificationRequired)
    ?.map((details) => {
      const buyerInfo = thirdParties.find(
        (thirdParty) => thirdParty.party?.id === details.thirdPartyId
      )?.party;

      return {
        buyerCountryCode: buyerInfo?.address?.countryCode,
        companyName: activeCustomer?.customerName,
        financierName: contractDetails?.factor?.name,
        buyerName: buyerInfo?.name || '',
        buyerCode: buyerInfo?.registrationCode,
        ...details
      };
    });

  return mappedVerifierDetails;
};

export const selectIsVerifiersLoading = (state: InvoicesState) => state.isVerifiersLoading;
export const selectIsVerifiersLoadFailed = (state: InvoicesState) => state.isVerifiersLoadFailed;

export const selectIsInvoicesHasNoItems = (state: InvoicesState) =>
  !selectIsInvoicesLoading(state) && !selectIsInvoicesLoadFailed(state) && !selectInvoicesList(state)?.length;
export const selectInvoicesTotalsByContract = (state: InvoicesState) => state.totalsForContract;

export const selectUploadedInvoicesList = (state: InvoicesState) => state.uploadedInvoices || [];
export const selectUploadedInvoicesWithMarkedDublicates = (
  uploadedInvoices: UploadInvoices,
  invoices: SubmittedInvoice[]
) => {
  const uploadedInvoicesWithDublicatesMarked = uploadedInvoices.map((uploadedInvoice) => {
    const { validationErrors } = uploadedInvoice || {};
    const hasDublicatesInUploadedList = uploadedInvoices.some(
      (invoice) =>
        invoice.invoiceNumber?.toLowerCase() === uploadedInvoice.invoiceNumber.toLowerCase() &&
        invoice.id !== uploadedInvoice.id
    );

    const hasDublicatesInList = invoices.some(
      (invoice) =>
        invoice.invoiceNumber?.toLowerCase() === uploadedInvoice.invoiceNumber.toLowerCase() &&
        invoice.id !== uploadedInvoice.id
    );

    if (hasDublicatesInUploadedList || hasDublicatesInList) {
      return {
        ...uploadedInvoice,
        status: 'PROCESSED_INVALID' as UploadInvoiceStatusType,
        validationErrors: validationErrors
          ? [...validationErrors, INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.invoiceNumberShouldBeUnique]
          : [INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.invoiceNumberShouldBeUnique]
      };
    }

    return uploadedInvoice;
  });

  return uploadedInvoicesWithDublicatesMarked;
};
export const selectUploadedInvoicesContacts = (state: InvoicesState) => state.uploadedInvoicesContacts || [];

export const selectSummaryData = (state: InvoicesState) => state.summaryData;

export const selectSupportDocumentsFiles = (state: InvoicesState) => state.supportDocuments;
export const selectSupportDocumentsFileNames = (state: InvoicesState) =>
  selectSupportDocumentsFiles(state)?.fileNames || [];

export const selectUploadedInvoicesStatus = (
  invoicesToCheckStatuses: UploadInvoices
): UploadedInvoicesStatus => {
  const isStillProcessing = !!invoicesToCheckStatuses?.some((invoice) => invoice.status === 'CREATED');
  const withErrors = !!invoicesToCheckStatuses?.some((invoice) => invoice.status === 'PROCESSED_INVALID');

  if (isStillProcessing && !withErrors) {
    return 'NOT_PROCESSED';
  }

  if (withErrors && !isStillProcessing) {
    return 'WITH_ERRORS';
  }

  if (withErrors && isStillProcessing) {
    return 'NOT_PROCESSED_WITH_ERRORS';
  }

  return 'OK';
};

const statusSortWeight: StatusSortWeight = {
  OVERDUE: 100,
  PARTIALLY_PAID: 90,
  FINANCED: 80
};

const byStatus = (a: SubmittedInvoice, b: SubmittedInvoice) => {
  const AWeight = a.invoiceStatus ? statusSortWeight[a.invoiceStatus] : statusSortWeight.OVERDUE;
  const BWeight = b.invoiceStatus ? statusSortWeight[b.invoiceStatus] : statusSortWeight.OVERDUE;

  return (BWeight || 0) - (AWeight || 0);
};

const byDate = (a: SubmittedInvoice, b: SubmittedInvoice) => {
  if (b.invoiceDueDate && a.invoiceDueDate) {
    return new Date(b.invoiceDueDate).getTime() - new Date(a.invoiceDueDate).getTime();
  }

  return 0;
};

const sortForUpcomingPayments = (content: SubmittedInvoice[]) => {
  return content.sort((a: SubmittedInvoice, b: SubmittedInvoice) => {
    const comparisonByStatus = byStatus(a, b);

    if (comparisonByStatus) {
      return comparisonByStatus;
    }

    return byDate(a, b);
  });
};

export const selectSortedInvoicesListForUpcomingPayments = (state: InvoicesState) =>
  sortForUpcomingPayments(selectInvoicesList(state));

export const selectUpcomintPaymentsTableItems = createSelector(
  selectInvoicesList,
  (payments): UpcommingPaymentItem[] => {
    return (
      payments?.map((payment) => {
        const { invoiceType, invoiceNumber, invoiceDueDate, invoiceStatus } = payment || {};

        return {
          ...payment,
          type: invoiceType,
          number: invoiceNumber,
          dueDate: invoiceDueDate,
          status: invoiceStatus
        };
      }) || []
    );
  }
);
